.App {
  text-align: center;
}

html {
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

html::-webkit-scrollbar {
    display: none;
}

body {
  width: 100vw;
  margin: 0px;
  background-color: #111111;
}

.big-text {
  word-wrap: break-word;
  text-align: center;
  position: absolute;
  font-size: 1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  opacity: 0;
  transition: 0.5s opacity;
}

.section-headers{
  font-size: 2em;
  color: white;
  height: 50vh;
  position: relative;
  display: block;
  padding-top: 5vh;
}

.topnav {
  background-color: #333;
  overflow: hidden;

  position: fixed;
  width: 100%;
  transition: top 0.3s;
  z-index: 1000;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 1vh 1vw;
  text-decoration: none;
  font-size: 1;

  display: block;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #111111;
  color: white;
}

.contact {
  grid-column: 1;
  padding: 0 0 20px 0;
  font-size: 1em;
  text-align: center;
}

.menu {
  grid-column: 2;
  padding: 0 0 20px 0;
  font-size: 1em;
  text-align: center;
  color:white;
}

.recent {
  grid-column: 3;
  padding: 0 0 20px 0;
  font-size: 1em;
  text-align: center;
  color:white;
}

.newsletter {
  grid-column: 4;
  padding: 0 0 20px 0;
  font-size: 1em;
  text-align: center;
  color:white;

}

footer {
  display: grid;
  color:white;
  font-size: .75em;
  background-color: #333;
}
